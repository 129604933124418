import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p style={{"font-size": 30} }>BEMALU COMEX SPA</p>
        <a
          className="App-link"
          href="https://www.linkedin.com/in/sebastiancuellar/"
          target="_blank"
          rel="linkedin @sebastiancuellar"
        >
          Creado con 🧡 en Arica 🇨🇱
        </a>
      </header>
    </div>
  );
}

export default App;
